@charset "UTF-8";
/**
 * fifty-fifty Component — TJ
 */
.fifty-fifty .component-content .column-splitter > div,
.fifty-fifty .component-content .column-splitter .magazine,
.fifty-fifty-left .component-content .column-splitter > div,
.fifty-fifty-left .component-content .column-splitter .magazine,
.fifty-fifty-right .component-content .column-splitter > div,
.fifty-fifty-right .component-content .column-splitter .magazine {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
}

.fifty-fifty .component-content .column-splitter .magazine,
.fifty-fifty-left .component-content .column-splitter .magazine,
.fifty-fifty-right .component-content .column-splitter .magazine {
  padding-bottom: 30px;
  padding-top: 30px;
}

.fifty-fifty .component-content .column-splitter .image,
.fifty-fifty .component-content .column-splitter .component-content,
.fifty-fifty .component-content .column-splitter a,
.fifty-fifty-left .component-content .column-splitter .image,
.fifty-fifty-left .component-content .column-splitter .component-content,
.fifty-fifty-left .component-content .column-splitter a,
.fifty-fifty-right .component-content .column-splitter .image,
.fifty-fifty-right .component-content .column-splitter .component-content,
.fifty-fifty-right .component-content .column-splitter a {
  height: 100%;
  width: 100%;
}

.fifty-fifty .component-content .column-splitter .rich-text,
.fifty-fifty-left .component-content .column-splitter .rich-text,
.fifty-fifty-right .component-content .column-splitter .rich-text {
  min-height: 400px;
}

@media (min-width: 1025px) {
  .fifty-fifty .component-content .column-splitter .rich-text.large-area,
  .fifty-fifty-left .component-content .column-splitter .rich-text.large-area,
  .fifty-fifty-right .component-content .column-splitter .rich-text.large-area {
    min-height: 600px;
  }
}

.fifty-fifty .component-content .column-splitter img,
.fifty-fifty-left .component-content .column-splitter img,
.fifty-fifty-right .component-content .column-splitter img {
  font-family: 'object-fit: cover;';
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.fifty-fifty-left .component-content .column-splitter > div:nth-of-type(1) {
  padding-right: 0;
}

.fifty-fifty-left .component-content .column-splitter > div:nth-of-type(2) {
  padding-left: 0;
}

@media (max-width: 991px) {
  .fifty-fifty-left .component-content .column-splitter > div:nth-of-type(1) {
    order: 2;
  }
  .fifty-fifty-left .component-content .column-splitter > div:nth-of-type(2) {
    order: 1;
  }
}

.fifty-fifty-right .component-content .column-splitter > div:nth-of-type(1) {
  order: 2;
  padding-left: 0;
}

.fifty-fifty-right .component-content .column-splitter > div:nth-of-type(2) {
  order: 1;
  padding-right: 0;
}

@media (max-width: 991px) {
  .fifty-fifty-right .component-content .column-splitter > div:nth-of-type(1) {
    padding-left: 15px;
  }
}
